"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = hasScrollbar;

function hasScrollbar() {
  if (typeof window === 'undefined') {
    return false;
  }

  window.document.body.style.overflowY = 'scroll';
  var hasScrollBar = window.innerWidth > document.documentElement.clientWidth;
  window.document.body.style.removeProperty('overflowY');
  return hasScrollBar;
}