import React, { useMemo } from 'react';

import {
    GatsbyImage,
    GatsbyImageProps,
    IGatsbyImageData,
    getImage,
} from 'gatsby-plugin-image';

import Picture, { PictureProps } from '@components/Picture';

import { cls } from '@scss';

import styles from './styles.module.scss';

interface ImageData extends IGatsbyImageData {
    publicURL: string;
}
export interface GatsbyPictureProps
    extends Omit<GatsbyImageProps, 'image'>,
        Omit<PictureProps, 'image' | 'onLoad'> {
    image: { file: ImageData } | string;
    alt: string;
    width?: number;
    height?: number;
}

// Unify layout, visit https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#aspectratio for more information
const GATSBY_LAYOUT: {
    [key in string]: IGatsbyImageData['layout'];
} = {
    fixed: 'fixed',
    full: 'fullWidth',
    fluid: 'constrained',
    background: 'fullWidth',
};

const GatsbyPicture: React.FC<GatsbyPictureProps> = ({
    image,
    alt = '',
    component: Wrapper = 'div',
    style,
    pictureStyle,
    classes,
    layout,
    height,
    width,
    ...props
}) => {
    const img = useMemo(() => {
        if (typeof image === 'string') {
            return image;
        }

        if (typeof image === 'object' && image) {
            let thisImage: ImageData | { file: ImageData } = image;

            if (Object.prototype.hasOwnProperty.call(image, 'file')) {
                thisImage = getImage(thisImage?.file);

                return (
                    thisImage ??
                    image?.file?.publicURL ??
                    image?.file?.url ??
                    image?.url
                );
            }

            const gatsbyImage = getImage(thisImage);

            return gatsbyImage ?? image?.publicURL ?? image?.url;
        }

        return undefined;
    }, [image]);

    if (typeof img === 'object') {
        const thisLayout =
            GATSBY_LAYOUT?.[layout as string] ?? img?.layout ?? 'constrained';

        const { height: heightImg, width: widthImg } = img;

        const wrapperStyle: React.CSSProperties = {
            ...(layout === 'background' && {
                position: 'absolute',
                inset: 0,
            }),
            ...(layout === 'full' && {
                position: 'relative',
                '--calculated-ratio': `${(heightImg / widthImg) * 100}%`,
            }),
            ...style,
        };

        const thisImage = {
            ...img,
            height: height ?? heightImg,
            width: width ?? widthImg,
            layout: thisLayout,
        };

        return (
            <GatsbyImage
                as={Wrapper}
                className={cls(
                    styles.wrapper,
                    styles[layout as string],
                    classes?.wrapper,
                )}
                imgClassName={cls(classes?.picture)}
                image={thisImage}
                style={wrapperStyle}
                imgStyle={pictureStyle}
                alt={alt}
                {...props}
            />
        );
    }

    if (typeof img === 'string') {
        return (
            <Picture
                image={img}
                alt={alt}
                classes={classes}
                layout={layout}
                height={height}
                width={width}
                {...props}
            />
        );
    }

    return null;
};

export default GatsbyPicture;
