"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCssProp = exports.getClassName = exports.cls = exports.className = void 0;
Object.defineProperty(exports, "salOptions", {
  enumerable: true,
  get: function get() {
    return _salOptions.salOptions;
  }
});

var _CssClassNames2 = _interopRequireDefault(require("./CssClassNames"));

require("~sal");

var _salOptions = require("./salOptions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _CssClassNames = new _CssClassNames2["default"]({}, ['text', 'media', 'transition', 'utility']),
    className = _CssClassNames.className,
    cls = _CssClassNames.cls,
    getClassName = _CssClassNames.getClassName,
    getCssProp = _CssClassNames.getCssProp;

exports.getCssProp = getCssProp;
exports.getClassName = getClassName;
exports.cls = cls;
exports.className = className;