import { useEffect } from "react";

export default function Fake() {
  useEffect(() => {
    if (typeof window === "undefined") {
      console.warn(
        `You are trying to use an soluto-design-system component without adding it.`
      );
    }
  }, []);

  return null;
}
