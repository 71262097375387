"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getDataProps;

function getDataProps(props) {
  var result = {};
  var dataArray = Object.keys(props).filter(function (key) {
    return key.startsWith('data-');
  });
  dataArray.forEach(function (key) {
    result[key] = props[key];
  });
  return result;
}