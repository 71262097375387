import { useContext, useEffect, useRef } from "react"
import { LangContext } from "./LangContext"

function LangWrapper({ children, language }) {
  const { state, setState } = useContext(LangContext)

  const previousLang = useRef(state?.lang)

  useEffect(() => {
    if (previousLang?.current !== language) {
      setState((state) => ({
        ...state,
        lang: language,
      }))

      previousLang.current = language
    }
  }, [language])

  return children
}

export default LangWrapper
