import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { ModalProvider } from 'design-system/src/hooks/useModal';
import { GTM_ID } from './gatsby-shared';
import 'design-system/src/scss/main.scss';

import locales from './locales.json';

const LUX_PATH_LABELS = {
    '/es/': 'Home',
    '/en/': 'Home',
    '/es/comunidad/': 'Blog Home',
};

const LUX_PATH_BLOG_POST_REGEX =
    /\/(es|en)\/(comunidad|innovacion-personas|innovation-people)\/[\w-]+\/[\w-]+/;

function getNewRouteLang(pathname) {
    if (!pathname) {
        return null;
    }

    let parts = pathname?.split('/').filter((i) => !!i);

    if (!parts.length) {
        return null;
    }

    if (locales.indexOf(parts[0]) !== -1) {
        return parts[0];
    }

    return null;
}

export const onRouteUpdate = ({ location }) => {
    const routeLang = getNewRouteLang(location.pathname);

    // Set cookie with new language
    if (routeLang) {
        window.document.cookie = `pr_lng=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }
};

// Wrap page element with ModalProvider
export const wrapPageElement = ({ element, props }) => {
    return <ModalProvider>{element}</ModalProvider>;
};

export const wrapRootElement = ({ element, props }) => {
    return (
        <>
            {element}
            {!!GTM_ID && (
                <Script
                    key="gtm-config"
                    id="gtm-config"
                    strategy={ScriptStrategy.idle}
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`
                    }} />
            )}
        </>
    )
};

export const onClientEntry = () => {
    let label = window.location.pathname;

    if (LUX_PATH_LABELS[label]) {
        label = LUX_PATH_LABELS[label];
    } else if (LUX_PATH_BLOG_POST_REGEX.test(label)) {
        label = 'Blog Post';
    }

    const LUX = window.LUX || {};
    LUX.label = label;
};
