"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = animate;

function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }

  var start = new Date().getTime(),
      timer = setInterval(function () {
    var step = Math.min(1, (new Date().getTime() - start) / time);

    if (prop) {
      elem[style] = from + step * (to - from);
    } else {
      elem.style.setProperty(style, "".concat(from + step * (to - from)).concat(unit));
    }

    if (step === 1) {
      clearInterval(timer);
    }
  }, 15);

  if (prop) {
    elem[style] = from;
  } else {
    elem.style.setProperty(style, "".concat(from).concat(unit));
  }
}