"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reactCollapse = require("react-collapse");

var _default = _reactCollapse.Collapse;
exports["default"] = _default;