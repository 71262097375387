function addSlashes(href) {
  href = href.indexOf("/") !== 0 ? `/${href}` : href
  href = href.endsWith("/") ? href : `${href}/`

  return href
}

function getAlternateLanguageUrls(pageContext, themeOptions, path) {
  const is404 = !pageContext?.id
  const thisLanguage = pageContext?.language || themeOptions.primaryLanguage
  const locales = themeOptions?.language
    ? Object.keys(themeOptions?.language)
    : []
  const currentPath = path
    ? path
    : pageContext?.pageUrl?.replace(pageContext?.url, "")
  const seoAlternateLanguages = pageContext?.seo?.alternateLanguages || []

  const alternateLanguages = locales.map((locale) => {
    if (locale === thisLanguage) {
      return {
        language: locale,
        active: true,
        href: addSlashes(!is404 ? currentPath : themeOptions.language[locale]),
      }
    }

    const thisSeoAlternate = seoAlternateLanguages.find(
      (item) => item?.language === locale
    )

    return {
      language: locale,
      active: false,
      href: addSlashes(thisSeoAlternate?.slug || themeOptions.language[locale]),
    }
  })

  return alternateLanguages
}

function getPageData(pageContext, themeOptions, path) {
  return {
    seo: pageContext?.seo || null,
    url: pageContext?.url || null,
    pageUrl: pageContext?.pageUrl || null,
    ...(pageContext?.pagination && {
      nextUrl: pageContext.pagination?.nextUrl,
      previousUrl: pageContext.pagination?.previousUrl,
    }),
    alternateLanguages: getAlternateLanguageUrls(
      pageContext,
      themeOptions,
      path
    ),
  }
}

module.exports = {
  getPageData,
}
