"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

/**
 * Creates DOM element to be used as React root.
 * @returns {HTMLElement}
 */
function createRootElement(id) {
  var rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}
/**
 * Appends element as last child of body.
 * @param {HTMLElement} rootElem
 */


function addRootElement(rootElem) {
  if (typeof document !== 'undefined') {
    var _document, _document$body, _document$body$lastEl;

    document.body.insertBefore(rootElem, (_document = document) === null || _document === void 0 ? void 0 : (_document$body = _document.body) === null || _document$body === void 0 ? void 0 : (_document$body$lastEl = _document$body.lastElementChild) === null || _document$body$lastEl === void 0 ? void 0 : _document$body$lastEl.nextElementSibling);
  }
}
/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */


function usePortal(id) {
  var rootElemRef = (0, _react.useRef)();
  (0, _react.useEffect)(function setupElement() {
    if (typeof window !== 'undefined') {
      var existingParent = document.querySelector("#".concat(id));
      var parentElem = existingParent || createRootElement(id);

      if (!existingParent) {
        addRootElement(parentElem);
      }

      parentElem.appendChild(rootElemRef.current);
      return function () {
        var _rootElemRef$current;

        rootElemRef === null || rootElemRef === void 0 ? void 0 : (_rootElemRef$current = rootElemRef.current) === null || _rootElemRef$current === void 0 ? void 0 : _rootElemRef$current.remove();

        if (!parentElem.childElementCount) {
          parentElem.remove();
        }
      };
    }
  }, [id]);

  function getRootElem() {
    if (typeof document !== 'undefined') {
      if (!rootElemRef.current) {
        rootElemRef.current = document.createElement('div');
      }

      return rootElemRef.current;
    }

    return undefined;
  }

  return getRootElem();
}

var _default = usePortal;
exports["default"] = _default;