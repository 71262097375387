"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withSolutoPrefix = void 0;

var isLocalLink = function isLocalLink(path) {
  return Boolean(path && !path.startsWith("http://") && !path.startsWith("https://") && !path.startsWith("//") && !path.startsWith('tel:') && !path.startsWith('mailto:') && !path.startsWith("#"));
};

function withPrefix(path, prefix) {
  var _href$split, _href$split2, _href;

  var trailingSlash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (!isLocalLink(path)) {
    return path;
  }

  if (path.startsWith("./") || path.startsWith("../")) {
    return path;
  }

  var base = prefix !== null && prefix !== void 0 ? prefix : "/";
  var href = "".concat(base !== null && base !== void 0 && base.endsWith("/") ? base.slice(0, -1) : base).concat(path.startsWith("/") ? path : "/".concat(path));
  var params = href.indexOf('?') !== -1 ? (_href$split = href.split('?')) === null || _href$split === void 0 ? void 0 : _href$split[1] : null;
  href = params ? (_href$split2 = href.split('?')) === null || _href$split2 === void 0 ? void 0 : _href$split2[0] : href;

  if (path.indexOf('#') !== -1) {
    return href;
  }

  if (!((_href = href) !== null && _href !== void 0 && _href.endsWith("/")) && trailingSlash) {
    href = "".concat(href, "/");
  }

  if (params) {
    href = href.concat("?".concat(params));
  }

  return href;
}

var withSolutoPrefix = function withSolutoPrefix(href, prefix) {
  var trailingSlash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (!href) {
    return '#';
  }

  var prefixedHref = withPrefix(href, prefix, trailingSlash);
  return prefixedHref;
};

exports.withSolutoPrefix = withSolutoPrefix;