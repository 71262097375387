const icons = {
    'hamburger-close': require('./src/img/icons/hamburger-close.svg'),
    'hamburger-default': require('./src/img/icons/hamburger-default.svg'),
    facebook: require('./src/img/icons/facebook.svg'),
    email: require('./src/img/icons/email.svg'),
    twitter: require('./src/img/icons/twitter.svg'),
    whatsapp: require('./src/img/icons/whatsapp.svg'),
    youtube: require('./src/img/icons/youtube.svg'),
    linkedin: require('./src/img/icons/linkedin.svg'),
    instagram: require('./src/img/icons/instagram.svg'),
    github: require('./src/img/icons/github.svg'),
    medium: require('./src/img/icons/medium.svg'),
    link: require('./src/img/icons/link.svg'),
};

module.exports = icons;
