const React = require("react")

const { LangProvider } = require("./context/LangContext")
const { PageProvider } = require("./context/PageContext")

const LangWrapper = require("./context/LangWrapper").default

exports.wrapRootElement = ({ element }, themeOptions) => {
  return (
    <LangProvider
      themeOptions={themeOptions}
      pathname={window.location.pathname}
    >
      {element}
    </LangProvider>
  )
}

exports.wrapPageElement = ({ element, props }, themeOptions) => {
  const { pageContext, path } = props

  return (
    <LangWrapper
      language={pageContext.language || themeOptions.primaryLanguage}
    >
      <PageProvider
        pageContext={pageContext}
        themeOptions={themeOptions}
        pathname={path}
      >
        {element}
      </PageProvider>
    </LangWrapper>
  )
}
