exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-topic-tsx": () => import("./../../../src/templates/blog-topic.tsx" /* webpackChunkName: "component---src-templates-blog-topic-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-resource-type-tsx": () => import("./../../../src/templates/resource-type.tsx" /* webpackChunkName: "component---src-templates-resource-type-tsx" */),
  "slice---src-components-footer-index-tsx": () => import("./../../../src/components/Footer/index.tsx" /* webpackChunkName: "slice---src-components-footer-index-tsx" */),
  "slice---src-components-header-index-tsx": () => import("./../../../src/components/Header/index.tsx" /* webpackChunkName: "slice---src-components-header-index-tsx" */)
}

